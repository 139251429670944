/* eslint-disable no-new */
/* eslint-disable dot-notation */
import { ResetCSS } from '@pancakeswap/uikit'
import 'antd/dist/antd.css'
import 'antd/dist/antd.dark.css'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { useIsLogin } from 'hooks/useIsLogin'
import '../style/animation.css'
import '../style/global.css'
import PublicLayout from 'layouts/PublicLayout'
import { Blocklist, Updaters } from '..'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  useAccountEventListener()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noodp,noydir" />

        {/* <!-- Link --> */}
        <link rel="apple-touch-icon" type="image/x-icon" href="https://admin.bunnychz.com/favicon.ico?v=1.1" />
        <link rel="shortcut icon" type="image/png" href="https://admin.bunnychz.com/favicon.ico?v=1.1" />

        {/* <!-- Og --> */}
        <meta property="og:title" content="Bunny - Welcome to Admin" />
        <meta
          property="og:description"
          content="Bunny is a Ukrainian fan bunny who aspires to become the mascot of Ukraine. Bunny will bring luck and call for peace to the country this Euro 2024! Let's join Bunny in supporting Ukraine and enjoy victory together!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Bunny - Welcome to Admin" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="PayX Credit" />
        <meta property="og:image" content="https://admin.bunnychz.com/graph.png?v=1.2" />
        <meta property="og:url" content="https://admin.bunnychz.com" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content="Bunny - Welcome to Admin" />
        <meta
          name="twitter:description"
          content="Bunny is a Ukrainian fan bunny who aspires to become the mascot of Ukraine. Bunny will bring luck and call for peace to the country this Euro 2024! Let's join Bunny in supporting Ukraine and enjoy victory together!"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@PayxCredit" />
        <meta name="twitter:image" content="https://admin.bunnychz.com/graph.png?v=1.2" />
        <meta name="twitter:creator" content="@Bunny" />

        {/* <!-- Title --> */}
        <title>Bunny - Welcome to Admin</title>
        <meta name="keywords" content="Bunny - Welcome to Admin" />
        <meta
          name="description"
          content="Bunny is a Ukrainian fan bunny who aspires to become the mascot of Ukraine. Bunny will bring luck and call for peace to the country this Euro 2024! Let's join Bunny in supporting Ukraine and enjoy victory together!"
        />
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          <ResetCSS />
          <GlobalStyle />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  fullLayout?: boolean
  authLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps, ...props }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Fragment

  useIsLogin()

  return (
    <>
      {/* <TokenUpdater /> */}
      <Layout>
        {Component.fullLayout ? (
          <Component {...pageProps} />
        ) : (
          <PublicLayout isAuth={Component?.authLayout}>
            <Component {...pageProps} />
          </PublicLayout>
        )}
      </Layout>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
    </>
  )
}

export default MyApp
