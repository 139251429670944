import React from 'react'
import { Layout } from 'antd'
import Menu from 'components/Menu'
import Footer from 'components/Footer'
import { Box } from 'widgets'
import AuthHeader from 'components/Menu/AuthHeader'
import { useRouter } from 'next/router'

const { Content } = Layout

const PublicLayout = ({ children, isAuth }) => {
  return (
    <Layout style={{ background: '#070F0F', minHeight: '100vh' }}>
      <div id="google_translate_element" />
      {isAuth ? <AuthHeader /> : <Menu />}
      <Content>
        <Box minHeight={isAuth ? '' : '100vh'} position="relative">
          {children}
        </Box>
      </Content>
    </Layout>
  )
}

export default PublicLayout
