import { Box } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { Dropdown } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { deleteAuthToken, getAuthToken } from 'helpers/FetchHelper'
import useIsMobile from 'hooks/useIsMobile'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import { updateUserInfo } from 'state/user/actions'
import { useUserData } from 'state/user/hooks/useUserData'
import { useGetUsdtWallet } from 'state/wallet/useGetWalletData'
import styled from 'styled-components'
import { Link } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import { formatCode } from 'helpers/CommonHelper'
import CollapseIcon from './CollapseIcon'
import DropdownUserMenu from './DropdownUserMenu'
import MobileSlider from './MobileSlider'
import { MenuEntry } from './types'

const WrapperHeader = styled.header<{ background: string }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  transition: 0.25s;
  z-index: 15;
  background: ${({ background }) => background};

  .content {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    padding: 16px;
    margin: 0 auto;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.xl} {
      padding: 32px 32px;
    }

    .menu-left {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 120px;

        ${({ theme }) => theme.mediaQueries.sm} {
          width: 180px;
        }

        ${({ theme }) => theme.mediaQueries.md} {
          width: 200px;
        }
      }
    }

    .menu-right {
      display: flex;
      align-items: center;

      .notify-icon {
        width: 30px;
        position: relative;
        margin-right: 16px;
        cursor: pointer;

        span {
          position: absolute;
          top: 2px;
          right: -3px;
          width: 18px;
          height: 18px;
          background: #f41706;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 100%;
        }
      }

      .menu-actions-desktop {
        position: relative;
        display: flex;
        align-items: center;

        button {
          &:not(:last-child) {
            margin-right: 13px;
          }
        }
      }

      .auth-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        min-width: 100px;
        padding: 8px 12px;
        font-weight: 700;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 16px;
          padding: 12px 24px;
        }

        &.sign-up {
          margin-left: 24px;
        }
      }
    }
  }
`

const UserBox = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-left: 32px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        stroke: #00a0ff;
      }
    }
  }
`

interface Props {
  menuItems: MenuEntry[]
  hasSider?: boolean
}

const mobileWith = 991

const Header: React.FC<Props> = ({ menuItems, hasSider = false }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isScroll, updateIsScroll] = useState(false)

  const dispatch = useDispatch()
  const isMobile = useIsMobile(mobileWith)
  const { account } = useWeb3React()
  const userData = useUserData()
  const myWallet = useGetUsdtWallet()

  const [showSlider, setShowSlider] = useState(false)
  const [login, setIsLogin] = useState(false)

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const toggleMenu = () => {
    setShowSlider((prev) => !prev)
  }

  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      updateIsScroll(true)
    } else {
      updateIsScroll(false)
    }
  }

  const handleSignOut = useCallback(() => {
    dispatch(updateUserInfo(undefined))
    const isDelete = deleteAuthToken()
    if (isDelete) {
      router.push('/sign-in')
    }
  }, [dispatch, router])

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      const authToken = getAuthToken()

      if (authToken) {
        setIsLogin(true)
      } else {
        setIsLogin(false)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  const logout = () => {
    deleteAuthToken()
    setIsLogin(false)
  }

  return (
    <>
      <WrapperHeader background={!isScroll ? '#0A14141a' : '#0A14141a'}>
        <div className="content">
          {!hasSider ? (
            <div className="menu-left">
              <Link href="/">
                <img src="/images/logo.png" alt="" />
              </Link>
            </div>
          ) : (
            <div />
          )}

          <div className="menu-right">
            {isMobile ? (
              <>
                {login && (
                  <BaseButton className="auth-btn sign-in" onClick={logout}>
                    Logout
                  </BaseButton>
                )}
              </>
            ) : (
              <div className="menu-actions-desktop">
                {!userData ? (
                  <>
                    {login && (
                      <BaseButton className="auth-btn sign-in" onClick={logout}>
                        Logout
                      </BaseButton>
                    )}
                  </>
                ) : (
                  <UserBox>
                    <Dropdown
                      overlay={
                        <DropdownUserMenu
                          userData={userData}
                          toggleMenu={toggleMenu}
                          onSignOut={handleSignOut}
                          myWallet={myWallet}
                        />
                      }
                      placement="bottomLeft"
                      trigger={['click']}
                      open={showSlider}
                      onOpenChange={toggleMenu}
                    >
                      <Box as="img" src={userData?.avatar} alt="" />
                    </Dropdown>
                  </UserBox>
                )}
              </div>
            )}
          </div>
        </div>
      </WrapperHeader>
      {isMobile && (
        <MobileSlider
          visible={showSlider}
          links={menuItems}
          accountEllipsis={accountEllipsis}
          toggleWallet={toggleWallet}
          toggleMenu={toggleMenu}
          userData={userData}
          onSignOut={handleSignOut}
          myWallet={myWallet}
        />
      )}
    </>
  )
}

export default Header
